<template>
  <div id="signup-dev-tools">
    DEV TOOLS
    <div class="signup-dev-tools-steps">
      <button @click="$store.commit('DECREMENT_STEP')">
        👈
      </button>
      step {{ step }}
      <button @click="$store.commit('INCREMENT_STEP')">
        👉
      </button>
      <br>
      <input
        v-model="full"
        type="checkbox"
      >
      full
      <input
        v-model="isInternal"
        type="checkbox"
      >
      <span>internal</span>
      <input
        v-model="debug"
        type="checkbox"
      >
      debug
    </div>
    <div
      v-show="debug"
      class="signup-dev-tools-debug"
    >
      debug tool<br>
      {{ (JSON.stringify($store.getters, null, 2)).replace(/,/g, ',\n') }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DevTools',
  data() {
    return {
      debug: false,
    };
  },
  computed: {
    ...mapGetters([
      'step',
    ]),
    isInternal: {
      get() {
        return this.$store.getters.isInternal;
      },
      set() {
        this.$store.commit('TOGGLE_INTERNAL');
      },
    },
    full: {
      get() {
        return this.$store.getters.debug;
      },
      set() {
        this.$store.commit('TOGGLE_DEBUG');
      },
    },
  },
};
</script>

<style lang='scss'>
#signup-dev-tools {
  width: 100%;
  position: fixed;
  z-index: 1;
  bottom: 30px;
  button {
    height: 35px !important;
  }
  .signup-dev-tools-steps {
    height: 35px;
    width: 250px;
    line-height: 30px;
    display: block;
    margin: auto;
    input {
      height: initial !important;
    }
  }
  .signup-dev-tools-debug {
    white-space: pre;
    float: left;
    text-align: left;
    line-height: 1;
    font-size: 10px;
    z-index: 1;
    position: fixed;
    left: 0;
    top: 80px;
    pointer-events: none;
  }
}
</style>
