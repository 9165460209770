<template>
  <div class="form-coupon-item">
    <div class="form-coupon-item-left">
      <small>{{ couponCode }}</small>
    </div>
    <div class="form-coupon-item-right float-right">
      <a
        href="https://aira.io/pricing"
        class="form-coupon-item-remove-link aira-action-link"
        @click.prevent="removeCoupon"
      >
        <small>Remove</small>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormCouponItem',
  props: {
    couponCode: {
      type: String,
      required: true,
    },
  },
  methods: {
    removeCoupon() {
      this.$store.commit('REMOVE_COUPON', this.couponCode);
      this.$store.dispatch('getOrderPreview');
    },
  },
};
</script>

<style lang='scss' scoped>
.form-coupon-item {
  div {
    display: inline-block;
  }
  .form-coupon-item-remove-link {
    font-weight: normal;
  }
}
</style>
