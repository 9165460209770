<template>
  <div id="lead-survey">
    <FormGroup
      for="leadCode"
      label="How did you hear about Aira?"
      class="w-100"
    >
      <FormSelect
        v-bind="$attrs"
        :options="leadOptions"
        :disabled="$attrs.readonly"
        bind-id="leadCode"
        get="leadCode"
        set="UPDATE_LEAD_CODE"
      />
    </FormGroup>
    <FormGroup
      v-if="leadValueOptions.length"
      for="leadValueOption"
      label="Please Specify"
      class="w-100"
    >
      <FormSelect
        v-bind="$attrs"
        :options="leadValueOptions"
        :disabled="$attrs.readonly"
        bind-id="leadValueOption"
        get="leadValueOption"
        set="UPDATE_LEAD_VALUE_OPTION"
      />
    </FormGroup>
    <FormGroup
      v-if="leadCode && (!leadValueOptions.length || leadValueOption === 'Other')"
      :label="leadValueLabel"
      for="leadValue"
      class="w-100"
    >
      <FormInput
        v-bind="$attrs"
        bind-id="leadValue"
        get="leadValue"
        set="UPDATE_LEAD_VALUE"
      />
    </FormGroup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import form from '../../../data/form.json';

import FormGroup from '../../Form/FormGroup.vue';
import FormSelect from '../../Form/FormSelect.vue';
import FormInput from '../../Form/FormInput.vue';

export default {
  name: 'SignupLeadSurvey',
  components: {
    FormGroup,
    FormSelect,
    FormInput,
  },
  data() {
    return {
      leadOptions: form.leadOptions.map((option) => ({
        value: option.value,
        text: option.text,
      })),
    };
  },
  computed: {
    ...mapGetters([
      'leadCode',
      'leadValueOption',
      'leadValue',
    ]),
    leadValueOptions() {
      const data = form.leadOptions.filter((option) => option.value === this.leadCode);
      if (data[0].options && data[0].options.length) {
        return data[0].options;
      }
      return [];
    },
    leadValueLabel() {
      switch (this.leadCode) {
        case 'Referral':
          return 'Specify their Email Address';
        case 'News':
        case 'Other':
          return 'Please Specify';
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss">
#lead-survey {
  text-align: left;
}
</style>
