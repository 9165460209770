<template>
  <b-form-group
    :invalid-feedback="invalidFeedback"
    :valid-feedback="validFeedback"
    :state="state"
    label="Promo Code (Optional)"
    label-for="promo-code-input"
  >
    <b-form-input
      id="promo-code-input"
      v-model="couponCode"
      :state="state"
      :disabled="btnDisabled"
      type="text"
      class="form-inline-input"
      @keyup.enter.native="applyCoupon"
    />
    <b-button
      :disabled="btnDisabled"
      variant="secondary"
      class="form-inline-button"
      @click="applyCoupon"
    >
      Apply
    </b-button>
    <div
      v-for="couponCodeItem in couponCodes"
      :key="couponCodeItem"
      class="form-coupons-applied"
    >
      <FormCouponItem :coupon-code="couponCodeItem" />
    </div>
  </b-form-group>
</template>

<script>
import { mapGetters } from 'vuex';
import FormCouponItem from './FormCouponItem.vue';

export default {
  name: 'FormCoupon',
  components: {
    FormCouponItem,
  },
  data() {
    return {
      couponCode: '',
      btnDisabled: false,
      invalidFeedback: null,
      validFeedback: null,
    };
  },
  computed: {
    ...mapGetters([
      'planCode',
      'couponCodes',
    ]),
    state() {
      return this.invalidFeedback === null ? null : 'invalid';
    },
  },
  watch: {
    couponCode(val) {
      if (val.length === 0) this.clearErrors();
    },
  },
  methods: {
    async applyCoupon() {
      if (this.couponCode === '') {
        return;
      }
      this.btnDisabled = true;
      this.clearErrors();
      // console.log('applyCoupon(): ', this.couponCode);

      // return if coupon is already applied
      if (this.couponCodes.includes(this.couponCode)) {
        this.btnDisabled = false;
        this.couponCode = '';
        this.pushErrors('Coupon code already applied.');
        return;
      }
      try {
        const response = await this.$store.dispatch('validateCoupon', this.couponCode);
        // only apply coupon if coupon is all plans or includes selected plan
        if (response) {
          // apply only att or nfb intro discount, not both, unfortunately is asynchronous and works on whatever is applied first
          // eslint-disable-next-line
          if (!((this.couponCode === 'nfb-intro-discount' || this.couponCode === 'att-perks-at-work') && (this.couponCodes.includes('nfb-intro-discount') || this.couponCodes.includes('att-perks-at-work')))) {
            this.$store.commit('APPLY_COUPON', this.couponCode);
          } else {
            this.pushErrors('Coupon code is not applicable with currently applied coupons.');
            return;
          }
          this.couponCode = '';
          await this.$store.dispatch('getOrderPreview');
        } else {
          this.pushErrors('Coupon code not applicable for this plan.');
        }
        this.btnDisabled = false;
      } catch (err) {
        this.pushErrors('Coupon code is invalid. Try another code.');
        this.btnDisabled = false;
      }
    },
    pushErrors(val) {
      this.invalidFeedback = val;
    },
    clearErrors() {
      this.invalidFeedback = null;
    },
  },
};
</script>

<style lang='scss' scoped>
@import '../../styles/objects/_form.scss';
.form-coupons-applied {
  @extend .w-75;
}
</style>
